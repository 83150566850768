img{
    max-width: 100%;
    border-radius: 1px;
}
.img-raised{
    box-shadow: $box-shadow-raised;
}

.img-footer{
    width: 60%;
}

.main-banner-img-row{
    opacity: 0.35;
    display: block;
}

.main-banner-img-row-md{
    opacity: 0.35;
    display: none;
}

.main-banner-img-col{
    opacity: 0.5;
    display: none;
}

.banenr-sm{
    display: none;
}