.modal-container {
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999999999;
  overflow: hidden;
  position: fixed;
}
.modal-main {
  width: 400px;
  height: 492px;
  position: absolute;
  background: #fff;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.modal-bg {
  width: 100%;
}
.modal-footer {
  width: 100%;
  height: 46px;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  font-family: "Noto Sans";
  font-weight: 500;
}
.sevenDays {
  background-repeat: no-repeat;
  background-position-y: center;
  padding: 0 10px 0 25px;
  cursor: pointer;
}
.select {
  background-image: url("../img/icon-select.svg");
  color: rgba(0, 0, 0, 0.9);
}
.unselect {
  color: #a4a4a4;
  background-image: url("../img/icon-unselect.svg");
}
.modal-container .btn-light {
  background-color: transparent;
  color: #000;
  padding: 10px;
  font-size: 12px;
  font-weight: 500;

}
@media (max-width: 800px) {
  .modal-main {
    width: 90vw;
    height: auto;
  }
  .modal-footer {
    font-size: 13px;
  }
}
