.section{
    padding: 70px 0;
    position: relative;
    background: $white-color;

    .row + .category{
        margin-top: $margin-base-vertical;
    }
}
.anchor {
    position: absolute;
    top: -65px;
    left: 0;
    width: 1px;
    height: 1px;
}
.section-navbars{
    padding-bottom: 0;
}

.section-full-screen{
    height: 100vh;
}
.section-signup{
    padding-top: 20vh;
}

.page-header{
  height: 100vh;
  max-height: 600px;
  padding: 0;
  color: $white-color;
  position: relative;
  overflow: hidden;

  & > .content{
    margin-top: 12%;
    text-align: center;
    margin-bottom: 50px;
  }

  &.page-header-small{
      max-height: 600px;
  }

  &:before{
      background-color: rgba(0,0,0,.3);
  }
  &::after {
      background-repeat: repeat-x;
      opacity: 0.5;
  }

  > .container{
    z-index: 2;
    padding-top: 105px;
    padding-bottom: 40px;
  }

    .page-header-image{
        position: absolute;
        background-color: $black-color;
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 100%;
    }

    .content-center{
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      -ms-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center;
      color: #FFFFFF;
      padding: 0 15px;
      width: 100%;
      max-width: 880px;
    }

    footer{
        position: absolute;
        bottom: 0;
        width: 100%;
    }
    .container{
        height: 100%;
        z-index: 1;
        text-align: center;
        position: relative;
    }

    .category,
    .description{
      color: $opacity-8;
    }

    &:after,
    &:before{
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        display: block;
        left: 0;
        top: 0;
        content: "";
    }
}

.clear-filter{
    &:after,
    &:before{
        display: none;
    }

    &[filter-color="orange"]{
        @include linear-gradient(rgba($black-color,.20), rgba(224, 23, 3, 0.6));
    }
}

.section-story-overview{
    padding: 50px 0;

    .image-container{
        height: 335px;
        position: relative;
        background-position: center center;
        background-size: cover;
        box-shadow: $box-shadow-raised;
        border-radius: .25rem;

        & + .category{
            padding-top: 15px;
        }

        &.image-right{
            z-index: 2;

            + h3.title{
                margin-top: 120px;
            }
        }

        &.image-left{
            z-index: 1;
        }

        &:nth-child(2){
            margin-top: 420px;
            margin-left: -105px;
        }
    }

    p.blockquote{
        width: 220px;
        min-height: 180px;
        text-align: left;
        position: absolute;
        top: 376px;
        right: 155px;
        z-index: 0;
    }
}

.section-nucleo-icons{
     .nucleo-container img{
        width: auto;
        left: 0;
        top: 0;
        height: 100%;
        position: absolute;
    }

     .nucleo-container{
        height: 335px;
        position: relative;
    }

     h5{
        margin-bottom: 35px;
    }

     .icons-container{
        position: relative;
        max-width: 450px;
        height: 300px;
        max-height: 300px;
        margin: 0 auto;

    }
     .icons-container i{
        font-size: 34px;
        position: absolute;
        left: 0;
        top: 0;
    }

     .icons-container i:nth-child(1){
        top: 5%;
        left: 7%;
    }

     .icons-container i:nth-child(2){
         top: 28%;
         left: 24%;
    }

     .icons-container i:nth-child(3){
         top: 40%;
    }

     .icons-container i:nth-child(4){
         top: 18%;
         left: 62%;
    }

     .icons-container i:nth-child(5){
         top: 74%;
         left: 3%;
    }

     .icons-container i:nth-child(6){
         top: 36%;
         left: 44%;
        font-size: 65px;
        color: #f96332;
        padding: 1px;
    }

     .icons-container i:nth-child(7){
         top: 59%;
         left: 26%;
    }

     .icons-container i:nth-child(8){
         top: 60%;
         left: 69%;
    }

     .icons-container i:nth-child(9){
        top: 72%;
        left: 47%;
    }

     .icons-container i:nth-child(10){
         top: 88%;
         left: 27%;
    }

     .icons-container i:nth-child(11){
         top: 31%;
         left: 80%;
    }

     .icons-container i:nth-child(12){
         top: 88%;
         left: 68%;
    }

     .icons-container i:nth-child(13){
         top: 5%;
         left: 81%;
    }

     .icons-container i:nth-child(14){
         top: 58%;
         left: 90%;
    }

     .icons-container i:nth-child(15){
        top: 6%;
        left: 40%;
    }
}

.section-images{
    max-height: 670px;
    height: 670px;

    .hero-images-container,
    .hero-images-container-1,
    .hero-images-container-2{
        margin-top: -38vh;
    }

    .hero-images-container{
        max-width: 670px;
    }

    .hero-images-container-1{
        max-width: 390px;
        position: absolute;
        top: 55%;
        right: 18%;

    }

    .hero-images-container-2{
        max-width: 225px;
        position: absolute;
        top: 68%;
        right: 12%;
    }
}


[data-background-color="orange"]{
    background-color: $orange-bg;
}
[data-background-color="black"]{
    background-color: $black-color;
}
[data-background-color="lightgray"]{
    background-color: #f9f9f9;
}

[data-background-color]:not([data-background-color="lightgray"]){
    color: $white-color;

    .title,
    .social-description h2,
    p,
    p.blockquote,
    p.blockquote small{
        color: $white-color;
    }

    .separator{
        background-color: $white-color;
    }

    .navbar.bg-white p{
        color: $default-color;
    }

    h1,h2,h3,h4,h5,h6,a:not(.btn):not(.dropdown-item),
    .icons-container{
      color: $white-color;
    }

    .input-group-text,
    .form-group.no-border .input-group-text,
    .input-group.no-border .input-group-text{
      color: $opacity-8;
    }

    .description,
    .social-description p{
        color: $opacity-8;
    }

    p.blockquote{
        border-color: $opacity-2;
    }

    //radio and checkboxes
    .checkbox label::before,
    .checkbox label::after,
    .radio label::before,
    .radio label::after{
        border-color: $opacity-2;
    }

    .checkbox label::after,
    .checkbox label,
    .radio label{
        color: $white-color;
    }

    .checkbox input[type="checkbox"]:disabled + label,
    .radio input[type="radio"]:disabled + label  {
        color: $white-color;
    }

    .radio input[type="radio"]:not(:disabled):hover + label::after,
    .radio input[type="radio"]:checked + label::after {
        background-color: $white-color;
        border-color: $white-color;
    }

    //buttons
    .btn[class*="btn-outline-"] {
        background-color: $transparent-bg;
        border-color: $opacity-5;
        color: $white-color;

        &:hover,
        &:focus,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active:focus,
        &:not(:disabled):not(.disabled).active:focus,
        &:active:hover,
        &.active:hover,
        .show > &.dropdown-toggle,
        .show > &.dropdown-toggle:focus,
        .show > &.dropdown-toggle:hover {
            background-color: $transparent-bg;
            border-color: $white-color;
            color: $white-color;
        }
    }

    //tabs
    .nav-tabs{
        > .nav-item{
            >.nav-link{
                i.now-ui-icons{
                    color: $white-color;
                }
            }
        }
    }

    &.section-nucleo-icons .icons-container i:nth-child(6){
        color: $white-color;
    }
}

//Custom
.page-header--after-bg::after{
    content:'';
    position:absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-image: url("../../img/home/banner_bottom.png");
    background-position: bottom 0px center;
    background-size: auto 300px;
    background-repeat: repeat-x;
}
.main-banner{
    height: 100%;
}

.main-banner-text{
    padding-bottom: 0px !important;
    display: table;
    div{
        padding-top: 100px;
    }
}

.main-banner-img{
    padding-bottom: 0px !important;
    max-width: 800px;
    width: 160%;
    left: -30%;
    display: table;
    div{
        display: table-cell;
        vertical-align: middle;
    }
    .main-banner-img-row{
        max-width: 800px;
        width: 160%;
        height: auto;
    }
}

.aboutus-banner{
    .page-header-image{
        top: 65px;
        background-color: white;
        display: flex;
        justify-content: space-between;
        img{
            height: 85%;
            width: auto;
        }
    }
}

.aboutus-banner-row {
    min-height: 100% !important;
    display: table;
    margin: auto !important;
    .aboutus-banner-text{
        display: table-cell;
        vertical-align: middle;
    }
}

.contact{
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 10px;
    
    .btn{
        width: 100%;
        height: 100%;
        padding: 15px 0px 15px 0px;
        text-align: center;
        border-radius: 10px;
        background-color: $npy-red;
        color: $white-color;
        &:hover,
        &focus,
        &:active,
        &:active:focus {
            box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2) !important;
            background-color: #fe4c4c !important;
            color: $white-color;
        }
        display: table;  
        text-align: center;
        h4{
            display: table-cell;
            vertical-align: middle;
        }
    }
}

.network-banner {
    > .container{
        padding-top: 65px;
    }
    .row{
        text-align: left;
        height: 100%;
    }
}

.network-feature {
    padding-left: 30px;
    padding-right: 30px;
    display: table;
    div{
        display: table-cell;
        vertical-align: middle;
    }
    h5{
        margin-top: 20px;
    }
}

.row {
    margin-left : 0px;
    margin-right : 0px;
}

.item-center{
    text-align: center;
}

.chain-banner{
    max-height: calc(400px + 70vw);
}

.chain-banner-back{
    background-color: black;
    height: 450px;
    position: relative;
    z-index: -1;
}

.chain-banner-front{
    position: relative;
    z-index: 2;
    top: -380px;
    padding-left: 20px;
    padding-right: 20px;
}

.faq-toggle{
    padding: 0px 25px 15px 25px;
    h5{
        color: $npy-gray;
        font-weight: lighter;
    }
}

.row-comment{
    padding-top: 20px;
}

.container-fluid{
    padding-left: 0px;
    padding-right: 0px;
}

.section-network{
    padding-left: 20px;
    padding-right: 20px;
    .div-list{
        margin-top: 70px;
    }
}

.section-network-feature{
    padding-top: 20px;
    .row{
        padding-top: 50px;
    }
    img{
        width: 80%;
    }
}

.section-chain-faq{
    padding-top: 50px;
    padding-bottom: 30px;
    h3{
        margin-bottom: 30px;
    }
    h4{
        margin-top: 30px;
    }
    .card{
        margin-bottom: 15px;
    }
}

.section{
    .container{
        .row{
            justify-content: center!important;
        }
    }
}

.section-isms{
    padding: 15px;
    text-align: center;
}

a.card-comment{
    .card-body{
        .row{
            justify-content: left!important;
        }
    }
}