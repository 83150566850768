.btn,
.navbar .navbar-nav > a.btn{
    border-width: $border-thick;
    font-weight: $font-weight-normal;
    font-size: $font-size-small;
    line-height: $line-height;
    margin: 10px 1px;
    border: none;
    border-radius: $border-radius-small;
    padding: $padding-btn-vertical $padding-btn-horizontal;
    cursor: pointer;

    @include btn-styles($default-color, $default-states-color);

    &:hover,
    &:focus{
        @include opacity(1);
        outline: 0 !important;
    }
    &:active,
    &.active,
    .open > &.dropdown-toggle {
         @include box-shadow(none);
         outline: 0 !important;
    }

    &.btn-icon {
        // see above for color variations
        height: $btn-icon-size-regular;
        min-width: $btn-icon-size-regular;
        width: $btn-icon-size-regular;
        padding: 0;
        font-size: $btn-icon-font-size-regular;
        overflow: hidden;
        position: relative;
        line-height: normal;

        &[class*="btn-outline-"]{
            padding: 0;
        }

        &.btn-sm{
            height: $btn-icon-size-small;
            min-width: $btn-icon-size-small;
            width: $btn-icon-size-small;

            i.fab,
            i.now-ui-icons{
                font-size: $btn-icon-font-size-small;
            }
        }

        &.btn-lg{
            height: $btn-icon-size-lg;
            min-width: $btn-icon-size-lg;
            width: $btn-icon-size-lg;

            i.now-ui-icons,
            i.fab{
                font-size: $btn-icon-font-size-lg;
            }
        }

        &:not(.btn-footer) i.now-ui-icons,
        &:not(.btn-footer) i.fab{
            position: absolute;
            top: 50%;
            left: 50%;
            -ms-transform: translate(-12px, -12px);
            -webkit-transform: scale(3)translate(-12px, -12px);
            transform: translate(-12px, -12px);
            line-height: 1.5626rem;
            width: 23px;
        }

    }

    &:not(.btn-icon) .now-ui-icons{
        position: relative;
        top: 1px;
    }
}

// Apply the mixin to the buttons
// .btn-default { @include btn-styles($default-color, $default-states-color); }
.btn-primary { @include btn-styles($primary-color, $primary-states-color); }
.btn-success { @include btn-styles($success-color, $success-states-color); }
.btn-info    { @include btn-styles($info-color, $info-states-color); }
.btn-warning { @include btn-styles($warning-color, $warning-states-color); }
.btn-danger  { @include btn-styles($danger-color, $danger-states-color); }
.btn-neutral { @include btn-styles($white-color, $white-color); }

.btn-outline-primary { @include outline-buttons($primary-color, $primary-states-color); }
.btn-outline-success { @include outline-buttons($success-color, $success-states-color); }
.btn-outline-info    { @include outline-buttons($info-color, $info-states-color); }
.btn-outline-warning { @include outline-buttons($warning-color, $warning-states-color); }
.btn-outline-danger  { @include outline-buttons($danger-color, $danger-states-color); }
.btn-outline-default { @include outline-buttons($default-color, $default-states-color); }

.btn-round{
    border-width: $border-thin;
    border-radius: $btn-round-radius !important;
    padding: $padding-btn-vertical  $padding-round-horizontal;

    &[class*="btn-outline-"]{
        padding: $padding-btn-vertical - 1  $padding-round-horizontal - 1;
    }
}
[class*="btn-outline-"]{
    border: $border;
    padding: $padding-btn-vertical - 1 $padding-round-horizontal - 1;
    background-color: $transparent-bg;
}

[class*="btn-outline-"],
.btn-link{
    &.disabled,
    &:disabled,
    &[disabled],
    fieldset[disabled] & {
        &,
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: $transparent-bg;
        }
    }
}

.btn-lg{
   @include btn-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $border-radius-large);
}
.btn-sm{
    @include btn-size($padding-small-vertical, $padding-small-horizontal, $font-size-base, $border-radius-small);
}

.btn-link{
    border: $none;
    padding: $padding-base-vertical $padding-base-horizontal;
    background-color: $transparent-bg;
}

.btn-toggle{
    border: $none;
    padding: $padding-base-vertical $padding-base-horizontal;
    background-color: $transparent-bg;
    &:hover,
    &:focus{
        @include opacity(1);
        outline: 0 !important;
    }
    &:active,
    &.active,
    .open > &.dropdown-toggle {
         @include box-shadow(none);
         outline: 0 !important;
    }
}

.btn-wd {
    min-width: 140px;
}
.btn-group.select{
    width: 100%;
}
.btn-group.select .btn{
    text-align: left;
}
.btn-group.select .caret{
    position: absolute;
    top: 50%;
    margin-top: -1px;
    right: 8px;
}

.btn-facebook {
  &,
  &:hover,
  &focus,
  &:active,
  &:active:focus {
    color: $social-facebook !important;
  }
}

.btn-twitter {

  &,
  &:hover,
  &focus,
  &:active,
  &:active:focus {
    color: $social-twitter !important;
  }
}

.btn-google {
  &,
  &:hover,
  &focus,
  &:active,
  &:active:focus {
    color: $social-google !important;
  }
}

.btn-github {
  &,
  &:hover,
  &focus,
  &:active,
  &:active:focus {
    color: $social-github !important;
  }
}

.btn-linkedin {
  &,
  &:hover,
  &focus,
  &:active,
  &:active:focus {
    color: $social-linkedin !important;
  }
}


//Custom
.btn-start {
    background-color: $npy-red !important;
    color: #ffffff !important;
    border-radius: 10px;
    padding: 10px 30px;
    &:hover,
    &focus,
    &:active,
    &:active:focus {
        background-color: #fe4c4c !important;
    }
    h3{
        margin-bottom: 0px;
    }
}

.btn-back{
    background-color: black !important;
    padding: 15px;
    margin-top: 10px;
    &:hover,
    &focus,
    &:active,
    &:active:focus {
        background-color: rgba(255, 255, 255, 0.1) !important;
    }
    h5{
        margin-bottom: 0px;
    }
}

.btn-stake {
    background-color: $npy-red !important;
    color: #ffffff !important;
    padding: 10px 30px 7px 30px;
    width: 100%;
    &:hover,
    &focus,
    &:active,
    &:active:focus {
        background-color: #fe4c4c !important;
    }
    h3{
        margin-bottom: 0px;
    }
}

.btn-chain-detail{
    margin-top: 30px;
    margin-bottom: 50px;
    padding: 15px 55px 15px 40px;
    color: black !important;
    background-color: $light-gray !important;
    h4{
        width: 100%;
        margin-top: 7px;
        padding-top: 0px !important;
    }
    svg{
        margin-top: -4px;
        font-size: xx-large;
    }
}

.btn-faq{
    padding: 15px 20px 15px 20px;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    text-align: left;
    h5{
        margin-bottom: 0px;
    }
    svg{
        width: 0.7em;
    }
}

.btn-contact{
    background-color: $npy-red !important;
    color: #ffffff !important;
    border-radius: 15px;
    padding: 20px 20px 20px 30px;
    width: 100%;
    &:hover,
    &focus,
    &:active,
    &:active:focus {
        background-color: #fe4c4c !important;
    }
    h4{
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }
}

.dropdown-toggle{
    display: flex;
    color: white !important;
    background-color: transparent !important;
    border: 1px solid white;
    border-radius: 10px;
    justify-content: space-between;
    &:hover,
    &focus,
    &:active,
    &:active:focus {
        border: 1px solid white !important;
        background-color: transparent !important;
    }
}

.dropdown-toggle::after{
    display: none;
}

.dropdown-menu{
    min-width: 100% !important;
    .dropdown-item{
        &:hover,
        &focus,
        &:active,
        &:active:focus {
            color: $npy-red !important;
            background-color: white;
        }
    }
}

.btn-top{
    padding-top: 0px;
    margin-left: 20px;
    margin-top: -3px;
    width: 100%;
    text-align: left;
    cursor: pointer;
    background-color: transparent !important;
    color: $npy-red !important;
    &:hover,
    &focus,
    &:active,
    &:active:focus {
        background-color: transparent !important;
        color: #fe4c4c !important;
        @include box-shadow(none);
    }
}

.btn-aboutus-more{
    margin-top: -5px;
    margin-bottom: 15px;
    margin-right: 10px;
    line-height: 1.45em;
    padding-top: 14px;
    background-color: black;
    color: white;
    svg{
        margin-bottom: 2px;
    }
    &:hover,
    &focus,
    &:active,
    &:active:focus {
        background-color: $npy-red !important;
    }
}

.btn-lang{
    background-color: black;
    color: white;
    border: 1px solid white;
}