// display flex was causing errors
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active{
    display: block;
}

.carousel{
    .carousel-inner{
        // box-shadow: $box-shadow-raised;
        border: 1px solid $light-gray;
        border-radius: $border-radius-small;
    }

    .card {
        max-width: 650px;
        margin: 60px auto;
        display: block;
    }
}

.carousel-control-prev,
.carousel-control-next{
    display: block;
    svg{
        -ms-transform: scale(3);
        -webkit-transform: scale(3);
        transform: scale(3);
        margin-top: 150%;
        padding: 2px;
        background-color: rgba(0,0,0,0.5);
        opacity: 0.8;
    }
}

.carousel-control-prev{
    text-align: left;
    svg{
        margin-left: 17px;
    }
}

.carousel-control-next{
    text-align: right;
    svg{
        margin-right: 17px;
    }
}