.card{
  border: 1;
  border-radius: $border-radius-small;
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 30px;

  .card-header{
    background-color: $transparent-bg;
    border-bottom: 0;
    background-color: transparent;
    border-radius: 0;
    padding: 0;
    img{
      width: 100%;
      height: 100%;
    }
  }

  .card-footer{
    padding: 0;
    margin-top: 15px;
    background-color: $transparent-bg;
    border:0;
  }

  &[data-background-color="orange"]{
      background-color: $primary-color;
  }

  &[data-background-color="vsys"]{
      background-color: #FF8836;
  }

  &[data-background-color="iost"]{
      background-color: #868080;
  }

  &[data-background-color="klaytn"]{
    background-color: #4F473B;
  }

  &[data-background-color="ultra"]{
      background-color: #7A51D1;
  }
  
  &[data-background-color="red"]{
      background-color: $danger-color;
  }

  &[data-background-color="yellow"]{
      background-color: $warning-color;
  }

  &[data-background-color="blue"]{
      background-color: $info-color;
  }

  &[data-background-color="green"]{
      background-color: darken($success-color, 5%);
  }
}

//Custom
a.card{
  text-decoration: none !important;
  &:hover,
    &focus,
    &:active,
    &:active:focus {
      box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2) !important;
    }
}

a.card-network{
  position: relative;
  min-height: 280px;
  margin-top: 30px;
  margin-bottom: 0px;
  &:hover,
  &focus,
  &:active,
  &:active:focus {
    border: 1px solid $npy-red;
    .card-header{
      background-color: $npy-red !important;
    }
  }

  .card-body{
    min-height: 240px;
    .card-logo{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 120px;
    }
  }

  .card-header{
    display: table;
    vertical-align: middle;
    text-align: center;
    background-color: $npy-black;
    color: $white-color;
    width: 100%;
    height: 100%;
    bottom: 0px;
    h5{
      padding: 10px;
    }
  }
}

a.card-network.coming-soon{
  pointer-events: none;
  .card-header{
    background-color: #dcdcdc !important;
  }
  &:hover,
  &focus,
  &:active,
  &:active:focus {
    box-shadow: none !important;
    .card-header{
      background-color: $npy-black !important;
    }
  }
}

a.card-more{
  position: relative;
  background-color: white !important;
  color: $npy-black !important;
  width: 15%;
  min-width: 200px;
  height: calc(100% - 30px);
  margin-top: 30px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  margin: 0;
  border: 1px solid $npy-black;
  border-radius: 30px;
  &:hover,
  &focus,
  &:active,
  &:active:focus {
      color: $npy-red !important;
      border: 1px solid $npy-red !important;
      box-shadow: none !important;
  }

  display: table;  
  text-align: center;
  h5{
    display: table-cell;
    vertical-align: middle;
  }
}

.card-item-center{
  text-align: center;
}

.card-comment{
  border-radius: 30px;
  .card-body{
    padding: 20px 25px 20px 30px;
    .row{
      padding: 10px;
      display: flex;
      flex-direction: row;
    }
  }
}

.card-aboutus-feature{
  border-width: 0px;
  .card-body{
    padding: 20px 5px 0px 0px;
  }
}

.card-chain{
  border-radius: 30px;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  padding: 0px;
  margin-bottom: 0px;
  img{
    margin-bottom: 10px;
  }
  hr{
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
}


.card-news{
  .card-body{
      h5{
        margin-top: 5px;
        margin-bottom: 5px;
      }
  }
}

a.card-isms{
  text-align: left;
  padding: 20px;
  margin-bottom: 0px !important;
  border: none;
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  &:hover,
  &focus,
  &:active,
  &:active:focus {
      box-shadow: none !important;
  }
  img{
    width: 150px;
    margin-right: 20px;
  }
  .isms-text {
    display: inline-block;
    max-width: 600px;
    h6 {
      line-height: 1.2;
      margin-bottom: 3px;
    }
  }
}