@media screen and (max-width: 991px){
    .sidebar-collapse{
        @include navbar-collapse();

        &.menu-on-left{
            .navbar-collapse{
                right: auto;
                left: 0;
                @include transform-translate-x(-300px);
            }
        }

        .nav-open &{
            .navbar-collapse{
                @include transform-translate-x(0px);
            }

            .wrapper{
                @include transform-translate-x(-150px);
            }

            .navbar-translate{
                @include transform-translate-x(-300px);
            }

            &.menu-on-left{
                .navbar-collapse{
                    @include transform-translate-x(0px);
                }

                .navbar-translate{
                    @include transform-translate-x(300px);
                }

                .wrapper{
                    @include transform-translate-x(150px);
                }

                #bodyClick{
                    right: auto;
                    left: 300px;
                }
            }
        }
    }

    .navbar-nav .nav-link i.fab,
    .navbar-nav .nav-link i.now-ui-icons {
      opacity: .5;
    }

    .bootstrap-collapse{
        .navbar .navbar-collapse{
            background: none !important;
        }
        @include navbar-responsive();
    }

    .profile-photo .profile-photo-small{
        margin-left: -2px;
    }

    .section-nucleo-icons .container .row > [class*="col-"]:first-child{
        text-align: center;
    }

    .footer{
        .copyright{
            text-align: center;
        }
    }

    .section-nucleo-icons .icons-container{
        margin-top: 65px;
    }

    .navbar-nav{
        .nav-link{
            i.fa,
            i.now-ui-icons{
                opacity: .5;
            }
        }
    }

    .section-images{
        height: 500px;
        max-height: 500px;

        .hero-images-container{
            max-width: 500px;
        }

        .hero-images-container-1{
            right: 10%;
            top: 68%;
            max-width: 269px;
        }

        .hero-images-container-2{
            right: 5%;
            max-width: 135px;
            top: 93%;
        }
    }
}

@media screen and (min-width: 992px){
  .navbar-collapse:not(.has-image) {
    background: transparent !important;
}
    .burger-menu{
        @include navbar-collapse();

        .nav-open & .navbar-collapse{
            @include transform-translate-x(0px);
        }

        .navbar-collapse {
            display: block !important;

            .navbar-nav {
                margin-top: 53px;
                height: 100%;
                z-index: 2;
                position: relative;
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -webkit-flex-direction: column;
                -ms-flex-direction: column;
                flex-direction: column;

                .nav-item{
                    margin: 0;
                }
            }
        }

        &.menu-on-left .navbar-collapse{
            right: auto;
            left: 0;
            @include transform-translate-x(-300px);
        }

        [class*="navbar-expand-"]{
            .navbar-nav .dropdown-menu{
                position: static;
                float: none;
            }

            .navbar-toggler{
                display: inline-block;
            }
        }

        .section-navbars .navbar-collapse{
            display: none !important;
        }

        .nav-open &{
            &.menu-on-left .navbar .navbar-translate{
                @include transform-translate-x(300px);
            }

            .navbar .navbar-translate{
                @include transform-translate-x(-300px);
            }

            &.menu-on-left .navbar-collapse{
                @include transform-translate-x(0px);
            }

            &.menu-on-left #bodyClick{
                right: auto;
                left: 300px;
            }
        }

        &.menu-on-left .navbar-brand{
            float: right;
            margin-right: 0;
            margin-left: 1rem;
        }
    }

    .navbar-nav{
        .nav-link{
            &.profile-photo{
                padding: 0;
                margin: 7px $padding-base-horizontal;
            }

            &.btn:not(.btn-sm){
                margin: 0;
            }
        }

        .nav-item:not(:last-child){
            margin-right: 5px;
        }

    }

    .section-nucleo-icons .icons-container{
        margin: 0 0 0 auto;
    }

    .dropdown-menu .dropdown-item{
        color: inherit;
        display: flex;
        align-items: center;

        i {
          margin-right: 5px;
          position: relative;
          top: 1px;
        }
    }

    .footer{
        .copyright{
            text-align: center;
            float: center;
        }
    }

    .network-banner{
        max-height: 45vw;
        min-height: 680px !important;
        .row{
            display: table;
            .col-sm-6{
                width: 50%;
                display: table-cell;
                vertical-align: middle;
            }
            .network-banner-img{
                text-align: center;
            }
        }
        .network-banner-img{
            img{
                width: 30vw;
            }
        }
    }
}

@media screen and (min-width: 768px){
    .image-container{
        &.image-right{
            top: 80px;
            margin-left: - 100px;
            margin-bottom: 130px;
        }

        &.image-left{
            margin-right: -100px;
        }
    }
    .nav-logo{
        margin: auto;
    }
}

@media screen and (max-width: 768px){
    .image-container{
        &.image-left{
            margin-bottom: 220px;

            p.blockquote{
                margin: 0 auto;
                position: relative;
                right: 0;
            }
        }
    }

    .nav-tabs{
        display: inline-block;
        width: 100%;
        padding-left: 100px;
        padding-right: 100px;
        text-align: center;

        .nav-item > .nav-link{
            margin-bottom: 5px;
        }
    }

    .landing-page .section-story-overview .image-container:nth-child(2){
        margin-left: 0;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 767){
    .navbar[class*='navbar-expand-'] .container{
        margin-left: 0;
        margin-right: 0;
    }

    .footer{
        .copyright{
            text-align: center;
        }
    }

    .section-nucleo-icons{
        .icons-container{
            i{
                font-size: 30px;

                &:nth-child(6){
                    font-size: 48px;
                }
            }
        }
    }

    .page-header{
        .container h6.category-absolute{
            width: 90%;
        }
    }
}

@media screen and (min-width: 991px) and (max-width: 1200px){
    .section-images{
        .hero-images-container-1{
            right: 9%;
            max-width: 370px;
        }

        .hero-images-container-2{
            right: 2%;
            max-width: 216px;
        }
    }
}

@media screen and (max-width: 768px){
    .section-images{
        height: 300px;
        max-height: 300px;

        .hero-images-container{
            max-width: 380px;
        }

        .hero-images-container-1{
            right: 7%;
            top: 87%;
            max-width: 210px;
        }

        .hero-images-container-2{
            right: 1%;
            max-width: 133px;
            top: 99%;
        }
    }
}

@media screen and (max-width: 517px) {
    .alert .alert-icon{
        margin-top: 10px;
    }
}

@media screen and (min-width: 1200px) {
    .section-images{
        .hero-images-container-1{
            top: 51%;
            right: 21%;
        }

        .hero-images-container-2{
            top: 66%;
            right: 14%;
        }
    }
}

// Ipad responsive portrait mode
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and ( orientation : portrait){
    .section-images .hero-images-container,
    .section-images .hero-images-container-1,
    .section-images .hero-images-container-2{
        margin-top: -15vh;
        margin-left: 80px;
    }

    .section-images .hero-images-container{
        max-width: 300px;
    }

    .section-images .hero-images-container-1{
        right: 28%;
        top: 40%;
    }

    .section-images .hero-images-container-2{
        right: 21%;
        top: 55%;
    }

    .index-page .category-absolute{
        top: 90vh;
    }
}

@media screen and (max-width: 580px) {
    .alert{
        button.close{
            position: absolute;
            right: 11px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

@media only screen and (min-height: 950px) {
  .index-page .page-header .category-absolute{
    margin-top: -330px;
  }
}

@media only screen and (min-height: 950px) {
    .index-page .page-header .category-absolute{
      margin-top: -330px;
    }
}

// Custom
@media screen and (max-width: 575px) {
    .aboutus-banner {
        height: 450px;
        min-height: 450px;
    }
    
    .aboutus-banner-row {
        min-height: 300px !important;
    }

    .aboutus-banner-text{
        padding-bottom: 80px;
        h1{
            font-size: 7vw;
        }
        h4{
            font-size: 4vw;
        }
    }

    .aboutus-banner{
        height: 800px;
        min-height: 800px;
        .page-header-image{
            img{
                display: block;
                -ms-transform: translateY(300px);
                -webkit-transform: translateY(300px);
                transform: translateY(300px);
                max-height: 75vw;
                overflow: hidden;
            }
            .left, .right{
                display: none;
            }
        }
    }

    .section-aboutus{
        position: absolute;
        top: 85vw;
        z-index: 2;
    }

    .carousel-control-prev{
        svg{
            -ms-transform: scale(2);
            -webkit-transform: scale(2);
            transform: scale(2);
            margin-left: 8px;
        }
    }

    .carousel-control-next{
        svg{
            -ms-transform: scale(2);
            -webkit-transform: scale(2);
            transform: scale(2);
            margin-right: 8px;
        }
    }

    .col-sm-5{
        flex: 0 0 48%;
        max-width: 48%;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 0.6em;
    }

    .section{
        padding: 30px 0;
    }

    a.card-network{
        margin-top: 10px;
        min-height: 0px;
        width: 100%;
        .card-body{
            padding: 15px 5px 15px 5px;
            min-height: 0px;
            .card-logo{
                height: 50px;
                img{
                    height: 35px;
                }
            }
        }
    }

    a.card-project{
        display: flex;
        flex-direction: row;
        margin-bottom: 5vw;
        .card-header{
            min-width: 25vw;
            max-width: 25vw;
            height: 25vw;
        }
        .card-body{
            padding: 3vw 3vw 0vw 2vw;
            min-height: 0px;
            h4{
                font-size: 4vw !important;
            }
            p{
                margin-top: 1vw;
                margin-bottom: 0px;
                font-size: 2.5vw !important;
            }
        }
    }

    a.card-more{
        margin-top: 10px !important;
        height: calc(100% - 10px);
        width: 92%;
    }

    a.card-comment{
        margin-top: 10px;
        min-height: 0px;
        width: 100%;
        margin-bottom: 10px;
        .card-body{
            padding: 15px 5px 15px 5px;
            min-height: 0px;
            .row{
                padding: 5px;
                justify-content: left !important;
                h4{
                    padding-top: 5px !important;
                }
                h5{
                    font-size: $font-size-h6;
                }
                img{
                    max-height: 30px;
                }
            }
            br{
                display: none;
            }
        }
    }

    h3, h4{
        font-size: 1.2em;
    }

    .row-comment{
        padding-top: 10px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .row-home-aboutus-desc{
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 20px;
        p{
            font-size: $font-size-h6;
            margin-bottom: 2px;
        }
    }

    .card-aboutus-feature{
        .card-body{
          padding: 15px 15px;
            h4{
                font-size: 4vw !important;
            }
            p{
                margin-top: 1vw;
                margin-bottom: 0px;
                font-size: 3vw !important;
            }
        }
    }

    .card-news{
        margin-bottom: 0px;
        .card-body{
            padding: 0.8rem;
            h5{
                font-size: 4vw;
            }
            h6{
                font-size: 3vw;
            }
        }
    }

    .section-network{
        h3{
            padding-left: 10px;
            padding-right: 10px;
            font-size: 6vw;
            line-height: 2em;
        }
        h5{
            padding-left: 10px;
            padding-right: 10px;
            font-size: 4vw;
            line-height: 1.5em;
        }
        .div-list{
            margin-top: 40px;
        }
    }

    .network-banner{
        min-height: 130vw !important;
        max-height: 130vw !important;
        .row{
            text-align: center;
        }
        h1{
            padding-top: 10vw;
            font-size: 5vw;
        }
        h4{
            font-size: 4vw;
        }
        img{
            width: 50vw;
        }
    }

    .section-contact{
        padding-top: 30vw;
        padding-bottom: 10vw;
        h2{
            font-size: 10vw;
        }
        h5{
            font-size: 5vw;
            padding-top: 5vw;
        }
        p{
            font-size: 4vw;
            margin-bottom: 0px;
        }
    }

    .page-header--after-bg::after {
        background-image: url("../../img/home/banner_row.png");
        background-position: top 400px left 32%;
        background-size: 130% auto;
    }

    .page-header-container::before{
        background:none;
    }
    
    .main-banner-text{
        h1{
            font-size: 10vw;
        }
        h4{
            font-size: 4vw;
        }
        div {
            padding-top: calc(100px - 2vw);
        }
    }

    .card-chain{
        img{
            min-height: 9vw;
            max-height: 9vw;
        }
        h2{
            font-size: 6.8vw;
        }
        h3{
            font-size: 5vw;
        }
        h4{
            font-size: 3vw;
        }
        h5{
            font-size: 3vw;
        }
        .card-body{
            padding: 7vw 7vw;
        }
    } 

    .section-chain{
        position: relative;
        top: 5vw;
    }

    .chain-banner-front{
        h5{
            font-size: 3vw;
        }
    }

    .section-chain-guide{
        h3{
            font-size: 7vw;
            margin-bottom: 5vw;
        }
        h4{
            font-size: 6vw;
            margin-bottom: 1vw;
        }
        h5{
            font-size: 4vw;
            margin-bottom: 2vw;
        }
    }
}

@media screen and (min-width: 576px) and (max-width: 767px){
    .aboutus-banner{
        height: 400px;
        min-height: 400px;
        .page-header-image{
            img{
                margin-top: 30px;
                width: 200px;
                height: 300px;
            }
        }
    }

    .aboutus-banner-text{
        h1{
            font-size: 5vw;
        }
        h4{
            font-size: 2vw;
        }
    }

    .card-aboutus-feature{
        .card-body{
            padding-left: 5px;
            h4{
                font-size: 2.5vw !important;
            }
            p{
                margin-top: 1vw;
                margin-bottom: 0px;
                font-size: 2vw !important;
            }
        }
    }

    .section-contact{
        padding-top: 20vw;
        padding-bottom: 10vw;
        h2{
            font-size: 10vw;
        }
        h5{
            font-size: 5vw;
            padding-top: 5vw;
        }
        p{
            font-size: 4vw;
            margin-bottom: 0px;
        }
    }
    
    .page-header--after-bg::after {
        background-image: url("../../img/home/banner_row_md.png");
        background-position: top 53vw left 32%;
        background-size: auto 304px;
    }

    .page-header-container::before{
        background:none;
    }

    .main-banner-text {
        h1{
            font-size: 7vw;
        }
        h4{
            font-size: 2.5vw;
        }
        .btn-start {
            border-radius: 10px;
            padding: 10px 25px;
            h3{
                font-size: 3vw;
            }
        }
        div {
            padding-top: calc(100px - 6vw);
        }
    }  

    .main-banner-img{
        max-width: 1300px;
        width: 300%;
        left: -50%;
        .main-banner-img-row{
            display: none;
        }
        .main-banner-img-row-md{
            max-width: 1300px;
            width: 300%;
            display: block;
        }
        .main-banner-img-col{
            display: none;
        }
    }
    
    .chain-banner-front{
        margin: auto;
        padding-left: 10vw;
        padding-right: 10vw;
        h5{
            font-size: 2vw;
        }
    }

    .card-chain{
        img{
            min-height: 9vw;
            max-height: 9vw;
        }
        h2{
            font-size: 6.8vw;
        }
        h3{
            font-size: 5vw;
        }
        h4{
            font-size: 3vw;
        }
        h5{
            font-size: 3vw;
        }    
    }
}

@media screen and (min-width: 576px) and (max-width: 991px){
    .section{
        .container{
            max-width: 900px;
        }
    }

    .row-home-aboutus-desc{
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 20px;
    }

    .network-banner{
        min-height: 75vw !important;
        max-height: 75vw !important;
        h1{
            padding-top: 0px;
            font-size: 4.5vw;
        }
        h4{
            font-size: 2vw;
        }
        .row{
            display: table;
            .col-sm-6{
                width: 50%;
                display: table-cell;
                vertical-align: middle;
            }
        }
        .network-banner-text{
            text-align: left;
        }
        .network-banner-img{
            img{
                width: 40vw;
            }
        }
    }
}

@media screen and (max-width: 767px){
    .img-footer{
        width: 40%;
    }

    .col-sm-12{
        padding-bottom: 20px;
    }

    .btn-chain-detail{
        width: 100%;
    }

    .nav-col-open{
        flex: 0 0 100%;
        min-width: 100%;
        max-height: 680px;
        padding-left: 0px;
        .navbar-collapse{
            height: 100vh;
            max-height: 680px;
            width: 100%;
            margin-top: 60px;
            
            a.nav-link{
                margin-bottom: 25px;
                padding-top: 15px !important;
                padding-bottom: 0px !important;
            }
            p{
                font-size: $font-size-h2;
            }
        }
        .navbar-toggler{
            -ms-transform: translateY(-42px);
            -webkit-transform: translateY(-42px);
            transform: translateY(-42px);
        }
    }

    .chain-contact-text{
        text-align: center;
    }

    .order-bottom{
        order:2
    }

    .section-network-feature{
        padding-top: 0px;
        padding-bottom: 0px;
        img{
            width: 90%;
        }
        .row{
            padding-top: 30px;
        }
    }

    .page-header.aboutus-banner > .container {
        padding-top: 25vw;
    }

    .network-feature{
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px;
        h3{
            font-size: 4.5vw;
        }
        h5{
            margin-top: 5px;
            font-size: 4vw;
        }
    }

    .section-aboutus-project{
        h2{
            padding-top: 0px;
            font-size: 5vw;
        }
        h4{
            padding-top: 4vw;
            font-size: 4vw;
        }
        h5{
            font-size: 3.5vw;
        }
        .btn{
            padding: 2vw 3vw 2vw 3vw;
            font-size: 2vw;
            margin-top: 3vw;
            margin-bottom: 2vw;
        }
    }

    .section-chain-faq{
        h5{
            font-size: 3.5vw;
        }
        h4{
            font-size: 4.3vw;
        }
    }

    a.card-isms{
        text-align: center;
    }

    .isms-col{
        .row{
            h5{
                font-size: 3.5vw;
            }
            h6{
                font-size: 2.5vw;
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 991px){
    .img-footer{
        margin-top: -5px;
        width: 100%;
    }

    .col-md-6{
        padding-bottom: 20px;
    }

    .footer-nav-1{
        padding-left: 30px;
    }

    .network-feature{
        padding-top: 10px;
        h3{
            font-size: 2.2vw;
        }
        h5{
            font-size: 1.6vw;
        }
    }

    .section-aboutus-project{
        .row{
            padding-bottom: 10px;
        }
        h2{
            padding-top: 0px;
            font-size: 4vw;
        }
        h4{
            padding-top: 1vw;
            font-size: 2vw;
        }
        h5{
            font-size: 2vw;
        }
        .btn{
            padding: 1vw 1.3vw 1vw 1.3vw;
            margin-top: 0.3vw;
            font-size: 1.5vw;
        }
    }

    .section-contact{
        padding-top: 15vw;
        padding-bottom: 6vw;
        h2{
            padding-top: 1vw;
            font-size: 3.5vw;
        }
        h5{
            padding-top: 1vw;
            font-size: 2vw;
        }
        p{
            font-size: 2vw;
            margin-bottom: 0px;
        }
    }

    .page-header--after-bg::after{
        background-size: auto 226px;
    }

    .main-banner-text {
        h1{
            font-size: 5vw;
        }
        h4{
            font-size: 1.8vw;
        }
        .btn-start {
            border-radius: 10px;
            padding: 10px 15px;
            h3{
                font-size: 2vw;
            }
        }
    }  

    .main-banner-img{
        padding-left: 0px;
        padding-right: 0px;
        position: relative;
        z-index: -1;
        max-width: 40vw;
        height: 1000px;
        left: 50px;
        top: -300px;
        .main-banner-img-row{
            display: none;
        }
        .main-banner-img-row-md{
            display: none;
        }
        .main-banner-img-col{
            max-width: 40vw;
            height: 1000px;
            display: block;
        }
    }

    .chain-banner-front{
        margin: auto;
        top: -370px;
        padding-left: 10vw;
        padding-right: 10vw;
        h5{
            font-size: 2vw;
        }
    }

    .chain-banner{
        max-height: calc(500px + 10vw);
    }

    .section-chain{
        position: relative;
        top: 2vw;
    }

    .card-chain{
        padding-top: 2.5vw;
        img{
            min-height: 6vw;
            max-height: 6vw;
        }
        h2{
            font-size: 4vw;
        }
        h3{
            font-size: 3.5vw;
        }
        h4{
            font-size: 2vw;
        }
        h5{
            font-size: 2vw;
        }
        
    }

    .section-chain-faq{
        h4{
            font-size: 2vw;
        }
    }

    .aboutus-banner{
        height: 480px;
        min-height: 480px;
        .page-header-image{
            img{
                margin-top: 30px;
                width: 260px;
                height: 380px;
            }
        }
    }
    .aboutus-banner-text{
        h1{
            font-size: 5vw;
        }
        h4{
            font-size: 2vw;
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1100px){
    .footer-nav-1{
        padding-left: 20px;
    }

    .section-aboutus-project{
        .row{
            padding-bottom: 20px;
        }
        h2{
            padding-top: 0px;
            font-size: 4vw;
        }
        h4{
            padding-top: 1vw;
            font-size: 2vw;
        }
        h5{
            font-size: 2vw;
        }
        .btn{
            padding: 1vw 1.3vw 1vw 1.3vw;
            margin-top: 0.3vw;
            font-size: 1.5vw;
        }
    }

    .section-contact{
        padding-top: 12vw;
        padding-bottom: 5vw;
        h2{
            padding-top: 1vw;
            font-size: 3.5vw;
        }
        h5{
            padding-top: 1vw;
            font-size: 2vw;
        }
        p{
            font-size: 2vw;
            margin-bottom: 0px;
        }
    }

    .page-header--after-bg::after{
        background-size: auto 256px;
    }

    .main-banner-text {
        h1{
            font-size: 5vw;
        }
        h4{
            font-size: 1.8vw;
        }
        .btn-start {
            border-radius: 10px;
            padding: 10px 20px;
            h3{
                font-size: 2vw;
            }
        }
    }  

    .main-banner-img{
        padding-left: 0px;
        padding-right: 0px;
        position: relative;
        z-index: -1;
        max-width: 40vw;
        height: 1200px;
        left: 50px;
        top: -300px;
        .main-banner-img-row{
            display: none;
        }
        .main-banner-img-row-md{
            display: none;
        }
        .main-banner-img-col{
            max-width: 40vw;
            height: 1200px;
            display: block;
        }
    }

    .chain-banner-front{
        margin: auto;
        top: -370px;
        padding-left: 10vw;
        padding-right: 10vw;
        h5{
            font-size: 2vw;
        }
    }

    .chain-banner{
        max-height: calc(500px + 5vw);
    }

    .section-chain{
        position: relative;
        top: 5vw;
    }

    .card-chain{
        padding-top: 2.5vw;
        img{
            min-height: 4vw;
            max-height: 4vw;
        }
        h2{
            font-size: 3.5vw;
        }
        h3{
            font-size: 3vw;
        }
        h4{
            font-size: 2vw;
        }
        h5{
            font-size: 1.7vw;
        }
        
    }

    .btn-stake{
        width: 50%;
        margin-top: 30px;
    }

    .aboutus-banner{
        .page-header-image{
            img{
                margin-top: 30px;
                width: 350px;
                height: 500px;
            }
        }
    }
}

@media screen and (min-width: 1101px){    
    .section-network{
        .container{
            .justify-content-center{
                justify-content: left !important;
            }
        }
    }

    .footer-nav-1{
      padding-left: 20px;
    }
  
    .section-aboutus-project{
          .row{
              padding-bottom: 30px;
          }
      }
  
      .section-contact{
          padding-top: 140px;
          padding-bottom: 50px;
          h2{
              padding-top: 15px;
          }
          h5{
              padding-top: 15px;
          }
      }
  
      .page-header{
          max-height: 680px;
      }
  
      .main-banner-text {
          .btn-start{
              margin-top: 50px;
              padding: 15px 35px;
          }
      }  
  
      .main-banner-img{
          padding-left: 0px;
          padding-right: 0px;
          position: relative;
          z-index: -1;
          max-width: 50vw;
          height: 1700px;
          left: 5vw;
          top: -300px;
          .main-banner-img-row{
              display: none;
          }
          .main-banner-img-row-md{
              display: none;
          }
          .main-banner-img-col{
              max-width: 50vw;
              height: 1700px;
              display: block;
          }
      }
  
      .chain-banner-front{
          max-width: calc(1140px + 10vw);
          margin: auto;
          top: -370px;
          padding-left: 6vw;
          padding-right: 6vw;
          h2{
              margin-bottom: 10px;
          }
      }
  
      .chain-banner{
          max-height: calc(620px + 3vw);
      }
  
      .card-chain{
          padding: 30px;
          .card-body{
            .col-md-6{
                h2{
                    margin-bottom: 30px;
                }
                h5{
                    margin-bottom: 30px;
                }
            }
          }
          img{
              max-width: 150px;
              height: auto;
              max-height: 48px;
          }
          hr{
            margin-top: 1em;
            margin-bottom: 1em;
          }
      }
  
      .btn-stake{
          width: 50%;
          margin-top: 35px;
          h4{
              font-size: $font-size-h5;
          }
      }
  
      .btn-chain-detail{
          margin-top: 80px;
      }
  
      .section-chain-guide{
          h3{
              margin-bottom: 20px;
          }
          h5{
              margin-bottom: 20px;
          }
      }

      .section-chain{
        position: relative;
        top: 50px;
      }

      .section-chain-faq .card {
        margin-bottom: 30px;
      }

      .btn-faq {
        padding: 25px 30px 25px 30px;

        svg{
            margin-top: 0.4em;
            width: 1em;
        }
      }

      .faq-toggle{
        padding: 10px 35px 30px 35px;
      }
  }